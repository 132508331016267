import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile, HeartIconStates, ImageProperties } from '../../../app.constants';
import { HttpService } from '../../../core/services/http/http.service';

@Component({
  selector: 'ia-feed-card',
  templateUrl: './feed-card.component.html',
  styleUrls: ['./feed-card.component.scss']
})
export class FeedCardComponent implements OnInit {
  @Input() feed;
  heartIcon = HeartIconStates.DEFAULT;
  defaultAvatar = UserProfile.USER_DEFAULT_PROFILE_URL;
  isThisFeedLiked = false;
  comment = '';
  isCommentBoxOpen = false;

  constructor(private httpService: HttpService, private router: Router) { }

  ngOnInit() {
  }

  goShopping() {
    this.httpService.post('posts/click', this.feed.feedId).subscribe(res => {
      window.open(this.feed.shopLink, "_blank");
    }, err => {
      console.log(err);
    });
  }

//  tagClick(tag: string) {
//    this.router.navigate(['/explore/'+tag.substr(1)]);
//  }
}
